import React from 'react';
import { Layout, Col, Card } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import automation from '../assets/automation.png';
import key from '../assets/key.png';
import background from '../assets/casesbg.png';
import logo from '../assets/casestagbot.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Cases() {
  return (
    <Content className={styles.basicInfo}>
      <div style={{ position: 'absolute', opacity: '100%' }}>
        <img src={background} style={{ maxWidth: '100%' }} />
      </div>

      <Row style={{ margin: '50px auto' }}>
        <h1 style={{ width: '100%', textAlign: 'center' }}>
          <span>AWS tagging</span> use cases.
        </h1>
      </Row>

      <Row
        gutter={32}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      >
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card bordered={false} className={styles.reinsuranceCardSix}>
            <h2>
              Resources
              <br />
              Management
            </h2>
            <p>
              Tagging helps AWS users effectively identify, filter, and manage
              their resources. The need for strategic and appropriate tagging
              often becomes painfully obvious as the number of resources being
              used Increases. Best practice (and the least pain incurred) would
              be to implement strong tagging policies from the onset of
              deployment. Good tagging practices becomes especially useful when
              organizations deploy multiple resources using automated processes
              such as CloudFormation or Terraform.
            </p>
          </Card>
        </Col>
      </Row>

      <Row
        gutter={32}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      >
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        ></Col>
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card bordered={false} className={styles.reinsuranceCardSix}>
            <h2>
              Security risk <br />
              Management
            </h2>
            <p>
              Tags can be leveraged to monitor resources that require an
              additional level of security. Many organizations run cloud
              workflows containing confidential and sensitive information that
              needs constant and stringent protection. Tagging such sensitive
              resources provides the necessary visibility to monitor key
              workflows and deployments.{' '}
            </p>
          </Card>
        </Col>
      </Row>

      <Row className={styles.respContainerTwo} style={{ margin: '50px auto' }}>
        <Card
          bordered={false}
          style={{ marginLeft: '16px', marginRight: '16px' }}
          className={styles.reinsuranceCardSix}
        >
          <h2>Cost Management</h2>
          <p>
            Cost management is arguably the most important use case when it
            comes to tagging resources on AWS. <br />
            <b>
              - Easily filter resource groups and prepare cost allocation
              reports broken down based on the selected tags <br />
              - Easily access cost allocation reports using tags associated with
              specific technical or business attributes such as “production” or
              “sales”
              <br />
            </b>
            With resources being accurately identified by tag, companies can
            gain a comprehensive understanding of their cloud costs, pinpoint
            resources that are either redundant or aren’t being used, and
            identify potential opportunities to save money.
          </p>
        </Card>
      </Row>

      <Row
        gutter={16}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      ></Row>

      <Row
        gutter={32}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      >
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card
            bordered={false}
            cover={
              <img
                className={styles.imgCardBinfo}
                src={automation}
                alt={'automation'}
              />
            }
            className={styles.reinsuranceCardFive}
          >
            <h2>
              Tagging is often used for automation. Resources that are grouped
              using unique tags can easily be identified and used in collective
              automation activities.
            </h2>
            <p>
              Examples :<br />
              - Automating the termination of temp dev resources at the end of
              the day
              <br />
              - Automating the movement of business workflows from one stage to
              another stage using s3 file tags
              <br />
              - Automating data backup, archiving & disaster recovery (DR) using
              cadence tags that set the frequency of these tasks
              <br />
            </p>
          </Card>
        </Col>
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card
            bordered={false}
            cover={
              <img className={styles.imgCardBinfo} src={key} alt={'key'} />
            }
            className={styles.reinsuranceCardFive}
          >
            <h2>
              Users can leverage tags to establish access control protocols. AWS
              IAM (Identity and Access Management) policies enable owners to
              define their own conditions and restrict user access using tags.
            </h2>

            <p>
              Examples :<br />
              - Using VPN Client tagged groups
              <br />
              - Tagging EC2 instances to restrict access to a specific resource
              <br />
            </p>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
