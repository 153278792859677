import React from 'react';
import { Layout } from 'antd';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Cta from '../components/Cta';
import Newsletter from '../components/Newsletter';
import Nav from '../components/Nav';
import CasesView from '../components/Cases';

export default function TagUseCases() {
  return (
    <Layout>
      <Header />
      <>
        <CasesView />
        <Cta />
        <Newsletter />
        <Nav />
      </>
      <Footer />
    </Layout>
  );
}
